











import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class ComponentNavTab extends Vue {
  @Prop({ default: '' }) activeTab!: String
  @Prop({ default: [] }) tabList!: Array<Object>

  @Watch('activeTab')
  onActiveTabChanged(val: string) {
    this.activeOption = val
  }
  activeOption: String = this.activeTab || ''
  toggleHandler(value: String) {
    this.activeOption = value
    this.$emit('tab-click', this.activeOption)
  }
}
